import React from 'react';

export default () => (
  <div className="Footer">
    <div className="Footer_bottom">
      <div className="Footer-Social-Info-Section">
        <ul id="footer-social-links">
          <li>
            <a
              href="https://www.youtube.com/user/FuzionDroid"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <i className="fab fa-youtube fa-3x"></i>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/FuzionDroid"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <i className="fab fa-twitter fa-3x"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/FuzionDroid/"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              <i class="fab fa-instagram fa-3x"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="Footer_top-links">
        <a
          href="https://merchforall.com/pages/sizes/"
          target="_blank"
          rel="noreferrer"
          className="Footer_top-link"
        >
          Sizes and Fit
        </a>
        |
        <a
          href="https://merchforall.com/pages/shipping-and-returns/"
          target="_blank"
          rel="noreferrer"
          className="Footer_top-link"
        >
          Shipping & Returns
        </a>
        |
        <a
          href="https://merchforall.com/contact/"
          target="_blank"
          rel="noreferrer"
          className="Footer_top-link"
        >
          Contact Us
        </a>
      </div>
      <div className="Footer_bottom_links_new">
        <span>© {new Date().getFullYear()} Merch for All LLC - All Rights Reserved</span>
        <a
          href="https://merchforall.com/legal/privacy/"
          target="_blank"
          rel="noreferrer"
          className="Footer_bottom-link"
        >
          Privacy Policy
        </a>
        |
        <a
          href="https://merchforall.com/legal/terms/"
          target="_blank"
          rel="noreferrer"
          className="Footer_bottom-link"
        >
          Terms of Service
        </a>
      </div>
    </div>
  </div>
);
